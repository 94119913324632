import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
/* eslint-disable */
import store from '../store'
import jwtDecode from 'jwt-decode'


Vue.use(VueRouter)

const routes = [
  // {
  //   /* ******************** */
  //   /* ORIGINALMENTE SOLO EL HOME TIENE el objeto meta */
  //   // original 
  //   // path: '/home',
  //   // name: 'home',
  //   /* ******************** */
  //   path: '/home',
  //   name: 'home',
  //   component: HomeView,
  //   // meta: {
  //   //   requireAuth: true 
  //   // }
  //   meta: {
  //     esGerente: true,
  //     esVendedor: true   
  //   }
  // },
  {
    // original
    // path:'/',
    // name:'login',
    path:'/',
    name:'login',
    component: () => import(/* webpackChunkName: "login" */ '../components/Login.vue'),
    meta: {
      libre: true 
    }
  },
  /* RECUPERAR CONTRASENA */
  {
    path:'/restore-password',
    name: 'restore-password',
    component: () => import (/* webpackChunkName: 'restorePassword' */'../components/RestorePassword.vue'),
    meta: {
      requireAuth: true
    }
  },
  /* </RECUPERAR CONTRASENA */

  // NUEVA CONTRASENA
  {
    path:'/password-reset/:id/:token',
    name: 'password-reset',
    component: () => import (/* webpackChunkName: 'passwordReset' */'../components/newPassword.vue'),
    meta: {
      requireAuth: true
    }
  },
  // </NUEVA CONTRASENA
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  //   meta: {
  //     libre: true 
  //   }
  // },
  {
    path:'/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contacto" */ '../views/ContactView.vue'),
    // meta: {
    //   requireAuth: true 
    // }
    meta: {
      esGerente: true,
      esVendedor: true   
    }
  },
  {
    path:'/customer',
    name: 'customer',
    component: () => import(/* webpackChunkName: "customer" */ '../views/CustomerView.vue'),
    // meta: {
    //   requireAuth: true 
    // }
    meta: {
      // requireAuth: true,
      esGerente: true,
      esVendedor: true  
    }
  },
  /* Razon social cliente */
  {
    path:'/razon-social-cliente/:customerId',
    name: 'razon-social-cliente',
    component: () => import(/* webpackChunkName: "razon-social-cliente" */ '../components/razonSocialCliente.vue'),
    // meta: {
    //   requireAuth: true 
    // }
    meta: {
      esGerente: true,
      esVendedor: true  
    }
  },
  /* NombreContacto */
  {
    path:'/nombre-contacto/:contactId',
    name: 'nombre-contacto',
    component: () => import(/* webpackChunkName: "nombre-contacto" */ '../components/NombreContacto.vue'),
    // meta: {
    //   requireAuth: true 
    // }
    meta: {
      esGerente: true,
      esVendedor: true  
    }
  },
  {
    path:'/detail',
    name: 'detail',
    component: () => import(/* webpackChunkName: "detail" */ '../views/DetailView.vue'),
    meta: {
      esGerente: true,
      esVendedor: true  
    }
  },
  {
    path:'/nuevo-usuario',
    name: 'nuevo-usuario',
    component: () => import(/* webpackChunkName: "nuevo-usuario" */ '../components/CrearUsuario.vue'),
    meta: {
      /* TODO OK..... */
      // requireAuth: true,
      esGerente: true  
    }
  },
  /* GRAFICAS */
  {
    path:'/grafica',
    name: 'grafica',
    component: () => import(/* webpackChunkName: "grafica" */ '../components/Grafica.vue'),
    meta: {
      /* TODO OK..... */
      // requireAuth: true,
      esGerente: true  
    }
  },
  /*** 6 sept 2023 - AJUSTES CRM ***/
  {
    path: '/notificacion-vendedor',
    name: 'notification-seller',
    component: () => import(/* webpackChunkName: "NotificationSeller" */ '../components/NotifySeller.vue'),
    meta: {
      esGerente: true,
      esVendedor: true  
    }
  },
  /** 8 sept 2023 - AJUSTES CRM */
  {
    path: '/ajuste',
    name: 'setting',
    component: () => import(/* webpackChunkName: "Setting" */ '../components/SettingAdmin.vue'),
    meta: {
      esGerente: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/* CODIGO ORIGINAL */
// router.beforeEach((to,from,next) =>{
//   let loggedIn = localStorage.getItem('token')
//   const protectedRoute = to.matched.some(record=>record.meta.requireAuth);
//   // if (protectedRoute && store.state.token === '') {
//   if (protectedRoute && !loggedIn) {
//     next({name:'login'})
//   } else {
//     next();
//   }
// })

/**
 * loggedIn : Verificar que el usuario este logueado
 * protectedRoute: Verificar que la ruta tenga la propiedad requireAuth
 * *********** VERIFICAR EL ROL ?  **********
 */

// CODIGO NEIDER
// router.beforeEach(async (to,from,next) =>{
//   let loggedIn = localStorage.getItem('token')
//   // let validarRol = store.state.userDb.role;
//   // const protectedRoute = to.matched.some(record=>record.meta.requireAuth);
  
//   console.log(store.state.userDb)

//   /* LA SOLUCION ES ALMACENAR EL rol en el localStorage porque al actualizar la pagina se limpia store.state.userDb */
//   const tokenDecodeUser = jwtDecode(loggedIn);
//   // console.log(userRoleFromLocalStorage)
//   console.log('=========== TOKEN LOCAL STORAGE =================')
//   console.log(loggedIn) 
//   console.log('=========== TOKEN DECODED =================')
//   console.log(tokenDecodeUser)
  

//   if(to.matched.some(record=> record.meta.libre)){
//     next()
//   }else{
//     // ORIGINAL CODE 
//     // if(loggedIn && store.state.userDb.role == 'GERENTE' && to.matched.some(record=> record.meta.esGerente)){ 
//     if(loggedIn && tokenDecodeUser.role == 'GERENTE' && to.matched.some(record=> record.meta.esGerente)){
//       next();
//     }else{
//       if(loggedIn && tokenDecodeUser.role == 'VENDEDOR' && to.matched.some(record=> record.meta.esVendedor)){
//         next();
//       } else{
        
//         next({name: 'login'});
//       }
//     }
//   }
// })

/* NEIDER 2 */
// let token = localStorage.getItem('token')
// const tokenDecodeUser = jwtDecode(token);
router.beforeEach((to,from,next)=>{
  try {
    let loggedIn = localStorage.getItem('token')
    if(!loggedIn){
      next()
    }else{
      let token = localStorage.getItem('token')
      const tokenDecodeUser = jwtDecode(token);
    
      if(to.matched.some(record=> record.meta.libre)){
        next()
      }else{
        if(loggedIn/*  && store.state.userDb */    && tokenDecodeUser.role == 'GERENTE' && to.matched.some(record=> record.meta.esGerente)){
          next();
        }else{
          if(loggedIn/*  && store.state.userDb */  && tokenDecodeUser.role == 'VENDEDOR' && to.matched.some(record=> record.meta.esVendedor)){
            next();
          } else{
            next({name: 'login'});
          }
        }
      }

    }
    
  } catch (error) {
    console.log('Error en el middleware:', error);
    next(false); // Llamada a next(false) para abortar la navegación
  }
})
/* NEIDER 2 */
// </CODIGO NEIDER

/* ACTUALIZACION DEL CODIGO -GPT4 */
// router.beforeEach((to,from,next)=>{
//   let loggedIn = localStorage.getItem('token');
//   const tokenDecodeUser = jwtDecode(loggedIn);

//   // Verifica si el usuario esta autenticado
//   if(loggedIn){
//     // Recupera la informacion del usuario desde el almacenamiento local
//     // const user = JSON.parse(localStorage.getItem('user'));
//     // Verifica si el rol del usuario coincide con los roles permitidos para la ruta actual
//     if((tokenDecodeUser.role === 'GERENTE' && to.matched.some(record => record.meta.esGerente)) || (tokenDecodeUser.role === 'VENDEDOR' && to.matched.some(record => record.meta.esVendedor))){
//       // Si el usuario tiene el rol permitido, permite el acceso a la ruta
//       next();
//     }else{
//       // Si el usuario no tiene el rol permitido, redirecciona al login
//       next({name: 'login'});
//     }
//   }else{
//     // Si no esta autenticado, redirecciona al login
//     next({ name: 'login'});
//   }
// })
/* ACTUALIZACION DEL CODIGO -GPT4 */



/* CODIGO DE UDEMY JUAN CARLOS - ARCILA */
// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.libre)) {
//     next();
//   } else {
//     if (store.state.usuario && store.state.usuario.rol == 'Administrador' && to.matched.some(record => record.meta.administrador)) {
//       next();
//     } else {
//       if (store.state.usuario && store.state.usuario.rol == 'Vendedor' && to.matched.some(record => record.meta.vendedor)) {
//         next();
//       } else {
//         if (store.state.usuario && store.state.usuario.rol == 'Almacenero' && to.matched.some(record => record.meta.almacenero)) {
//           next();
//         } else {
//           next({ name: 'login' });
//         }
//       }
//     }
//   }
// });

export default router
