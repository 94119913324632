<template>
  <div id="app">
    <header class="bg-dark">
      <div class="container">
        <!-- Navbar nuevo bootstrap4.6.3 -->
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
          <!--! LOGO EMPRESA -->
          <a class="navbar-brand" href="#">
            <img src="./assets/LogoTreea.png" width="85" height="50" class="d-inline-block align-top" alt="">
          </a>
          <!--! LOGO EMPRESA -->
          <a class="navbar-brand" href="#">CRM | Treea</a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent" style="padding:10px">
            <ul class="navbar-nav ml-auto">
              <!-- <li class="nav-item">
                <router-link :class="`nav-link ${$route.path === '/home' && 'active'} `" :to="{name:'home'}" v-if="isActive" v-show="esGerente || esVendedor" exact>Inicio</router-link>
              </li> -->
              <!-- !PROBAR PAGINACION -->
              <!-- <li class="nav-item">
                <router-link :class="`nav-link ${$route.path === '/about' && 'active'} `" :to="{name:'about'}" v-if="isActive">About</router-link>
              </li> -->
              <li class="nav-item">
                <router-link :class="`nav-link ${$route.path === '/nuevo-usuario' && 'active'} `" :to="{name:'nuevo-usuario'}" v-if="isActive" v-show="esGerente" exact>Usuario</router-link>
              </li>
              <!-- GRAFICAS -->
              <li class="nav-item">
                <router-link :class="`nav-link ${$route.path === '/grafica' && 'active'} `" :to="{name:'grafica'}" v-if="isActive" v-show="esGerente" exact>Gráfica</router-link>
              </li>
              <li class="nav-item">
                <router-link :class="`nav-link ${$route.path === '/customer' && 'active'} `" :to="{name:'customer'}" v-if="isActive" v-show="esGerente || esVendedor" exact>Cliente</router-link>
              </li>
              <li class="nav-item">
                <router-link :class="`nav-link ${$route.path === '/contact' && 'active'} `" :to="{name:'contact'}" v-if="isActive" v-show="esGerente || esVendedor" exact>Contacto</router-link>
              </li>
              <li class="nav-item">
                <router-link :class="`nav-link ${$route.path === '/detail' && 'active'} `" :to="{name:'detail'}" v-if="isActive" v-show="esGerente || esVendedor" exact>Acercamiento</router-link>
              </li>
              <!-- ? add 6 sept - 2023 AJUSTES CRM -->
              <li class="nav-item">
                <router-link :class="`nav-link ${$route.path === '/notificacion-vendedor' && 'active'} `" :to="{name:'notification-seller'}" v-if="isActive" v-show="esGerente || esVendedor" exact>
                  <i class="fa-regular fa-bell"></i>
                  <!-- <span class="badge rounded-pill badge-notification bg-danger">{{ nNotifyGestor }}</span> -->
                  <span class="badge rounded-pill badge-notification bg-danger">{{ nNotify }}</span>
                </router-link>
              </li>
              <!-- ? add 8 sept - 2023 AJUSTES CRM -->
              <li class="nav-item">
                <router-link :class="`nav-link ${$route.path === '/ajuste' && 'active'} `" :to="{name:'setting'}" v-if="isActive" v-show="esGerente" exact>Ajustes</router-link>
              </li>
              <li class="nav-item ">
                <router-link :class="`nav-link ${$route.path === '/' && 'active'}`" :to="{name:'login'}" v-if="!isActive" exact>Login</router-link>
              </li>
              <!-- LOGOUT -->
              <li class="nav-item dropdown" v-if="isActive">
                <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                  <i class="fa-regular fa-circle-user"></i>
                   {{ getInfoUser.name.charAt(0) }}
                </a>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="#">{{  getInfoUser.name }}</a>
                  <a class="dropdown-item" href="#">{{  getInfoUser.email }}</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">
                    <i class="fa-regular fa-user"></i>
                    Perfil
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item logout" @click="logOut()">
                    <i class="fa-solid fa-right-from-bracket"></i>
                    Cerrar Sesión
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
        <!-- Navbar nuevo bootstrap4.6.3 -->
      </div>
    </header>
    <router-view/>
  </div>
</template>

<script>
import { mapActions, mapGetters,mapState } from 'vuex'
export default {
  data(){
    return {}
  },
  created(){
    this.readToken();
  }, 
  methods: {
    ...mapActions(['logOut','readToken']),
  },
  computed: {
    ...mapGetters(['isActive','getInfoUser']),
    ...mapState(['userDb','nNotify','token']),
    /* CODIGO NUEVO PARA LOS ROLES */
    esGerente(){
      // return this.$store.state.userDb && this.$store.state.userDb.role == 'GERENTE';
      return this.userDb && this.userDb.role == 'GERENTE';
    },
    esVendedor(){
      // return this.$store.state.userDb && this.$store.state.userDb.role == 'VENDEDOR';
      return this.userDb && this.userDb.role == 'VENDEDOR';
    }
  }
}
</script>
<style>
header{
  padding:10px
}

span{
  font-size: 20px;
}
.active{
    border-bottom: 4px solid #42b983;
}
.nav-link{
    font-size: 20px;
}
.logout{
  cursor: pointer;
}
</style>
